<template>
  <v-container class="privilege-array-editor">
    <v-row no-gutters>
      <v-col>
        <span class="caption">{{ config.label }}</span>
      </v-col>
    </v-row>
    <v-row v-for="(privilege, key) in privileges" :key="key">
      <v-col>
        <v-text-field
          v-model="privilege.requestMethod"
          dense
          label="Request Method"
          @change="onPrivilegeUpdate"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="privilege.requestPath"
          dense
          label="Request Path"
          @change="onPrivilegeUpdate"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import clone from 'clone-deep';
export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
    attributeValue: {
      type: Array,
      default: () => [],
    },
    attributeKey: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    privileges() {
      const valueCopy = clone(this.attributeValue);
      valueCopy.push(this.emptyPrivilege());
      return valueCopy;
    },
  },
  mounted() {
    this.appendEndPrivilegeField();
  },
  methods: {
    onPrivilegeUpdate() {
      this.appendEndPrivilegeField();
      this.stripEmptyPrivilegeFields();

      const privileges = this.privileges.filter(p => !this.privilegeIsEmpty(p));

      this.mutate(privileges);
    },
    appendEndPrivilegeField() {
      const last = this.privileges[this.privileges.length - 1];

      if (!last || !this.privilegeIsEmpty(last)) {
        this.privileges.push(this.emptyPrivilege());
      }
    },
    emptyPrivilege() {
      return {
        requestMethod: '',
        requestPath: '',
      };
    },
    stripEmptyPrivilegeFields() {
      for (let i = 0; i < this.privileges.length - 1; i++) {
        if (this.privilegeIsEmpty(this.privileges[i])) {
          this.privileges.splice(i, 1);
        }
      }
    },
    privilegeIsEmpty(privilege) {
      return privilege.requestMethod === '' && privilege.requestPath === '';
    },
    mutate(newValue) {
      this.$emit('mutate', { [this.attributeKey]: newValue });
    },
  },
};
</script>

<style lang="scss" scoped>
.privilege-array-editor {
  width: auto;
  background-color: #fafafa;
  border-radius: 4px;
}
</style>
