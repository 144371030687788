<template>
  <v-row>
    <v-col
      v-for="(key, index) in keys"
      :key="key"
      :cols="index < keys.length - 1 ? 6 : null"
    >
      <v-text-field
        :value="attributeValue[key]"
        :label="`${config.label} (${key})`"
        @input="input => mutate(key, input)"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
    attributeValue: {
      type: Object,
      default: () => ({}),
    },
    attributeKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    keys() {
      const defaultKeys = this.config.keys || [];
      const extraKeys = Object.keys(this.attributeValue).filter(
        key => !defaultKeys.includes(key),
      );
      return [...defaultKeys, ...extraKeys];
    },
  },
  created() {
    this.initializeCRUDProperty();
  },
  methods: {
    mutate(key, value) {
      this.$emit('mutate', {
        [this.attributeKey]: { ...this.attributeValue, [key]: value },
      });
    },
    initializeCRUDProperty() {
      this.$emit('mutate', {
        [this.attributeKey]: this.keys.reduce(
          (obj, key) => {
            obj[key] = this.attributeValue[key] || '';
            return obj;
          },
          { ...this.attributeValue },
        ),
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
