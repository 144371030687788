<template>
  <div class="pt-3">
    <div class="caption">{{ config.label }}</div>
    <div :class="$style.itemsWrapper">
      <div
        v-for="(version, i) in versionHistory"
        :key="i"
        v-ripple
        class="mb-2"
        :class="$style.item"
        @click="() => loadVersion(version)"
      >
        <div class="subtitle-1">{{ transformEmail(version.authorEmail) }}</div>
        <div class="caption">{{ parseDate(version.creationDate) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../../../../utils/api-axios';
import transformBeforeMounted from '../../../../../utils/transform-before-mounted';
import configs from '../../../configs';
export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
    itemValue: {
      type: Object,
      required: true,
    },
    attributeKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      versionHistory: null,
    };
  },
  computed: {
    type() {
      return this.$route.params.type;
    },
    typeConfig() {
      return configs[this.type];
    },
    hasLoadedExistingStory() {
      return this.itemValue.id;
    },
  },
  created() {
    if (this.hasLoadedExistingStory) this.loadVersionHistory();
  },
  methods: {
    loadVersionHistory() {
      api
        .get(`/stories-service/stories/${this.itemValue.id}/version-history`)
        .then(response => {
          response.data.sort((a, b) => {
            return new Date(b.creationDate) - new Date(a.creationDate);
          });
          response.data.forEach(element => {
            transformBeforeMounted(element, this.typeConfig);
          });
          this.$set(this, 'versionHistory', response.data);
        });
    },
    parseDate(date) {
      const d = new Date(date);
      const hours = d
        .getHours()
        .toString()
        .padStart(2, '0');
      const minutes = d
        .getMinutes()
        .toString()
        .padStart(2, '0');
      return `${d.getFullYear()}-${d.getMonth()}-${d.getDay()} ${hours}:${minutes}`;
    },
    loadVersion(version) {
      delete version.creationDate;
      this.$emit('mutate', version);
    },
    transformEmail(email) {
      const name = email.replace('@thehumanaspect.com', ' @THA');
      return name[0].toUpperCase() + name.slice(1);
    },
  },
};
</script>

<style lang="scss" module>
.itemsWrapper {
  height: 240px;
  overflow-y: auto;
}

.itemsWrapper::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.itemsWrapper::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.item {
  cursor: pointer;
  padding: 2px 6px;
  border-left: 2px solid lightgray;
}
</style>
