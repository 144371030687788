export default function transformBeformMounted(data, config) {
  config.form.forEach(obj => {
    if (obj.fields instanceof Array) {
      obj.fields.forEach(field => {
        Object.keys(field).forEach(key => {
          if (field[key]['transformBeforeMounted']) {
            data[key] = field[key].transformBeforeMounted(data[key]);
          }
        });
      });
    } else {
      Object.keys(obj.fields).forEach(key => {
        if (obj.fields[key]['transformBeforeMounted']) {
          data[key] = obj.fields[key].transformBeforeMounted(data[key]);
        }
      });
    }
  });

  return data;
}
