<template>
  <v-datetime-picker
    :datetime="date"
    :label="config.label"
    :disabled="disabled"
    time-format="HH:mm:ss"
    dense
    @input="mutate"
  >
    <template slot="dateIcon">
      <v-icon>mdi-calendar-blank</v-icon>
    </template>
    <template slot="timeIcon">
      <v-icon>mdi-clock</v-icon>
    </template>
  </v-datetime-picker>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
    attributeValue: {
      type: [Date, String],
      default: () => new Date(),
    },
    attributeKey: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date:
        typeof this.attributeValue === 'string'
          ? new Date(this.attributeValue)
          : this.attributeValue,
    };
  },
  created() {
    this.initializeCRUDProperty();
  },
  methods: {
    mutate(newValue) {
      this.$emit('mutate', { [this.attributeKey]: newValue });
    },
    initializeCRUDProperty() {
      this.mutate(this.attributeValue);
    },
  },
};
</script>

<style lang="scss" scoped></style>
