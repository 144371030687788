import Vue from 'vue';
import * as types from '../../types';

import StringField from './field-types/StringField';
import NumberField from './field-types/NumberField';
import BooleanSwitch from './field-types/BooleanSwitch';
import TextArea from './field-types/TextArea';

import PrivilegeArrayEditor from './field-types/PrivilegeArrayEditor';
import ObjectMultiSelect from './field-types/ObjectMultiSelect';
import DatetimePicker from './field-types/DatetimePicker';
import MapEditor from './field-types/MapEditor';
import StoryImageChooser from './field-types/StoryImageChooser/index';
import StoryVersionHistory from './field-types/StoryVersionHistory';
import LookupSelector from './field-types/LookupSelector';
import LookupMultiSelector from './field-types/LookupMultiSelector/index';

export default Vue.component('ItemFormField', {
  props: {
    config: {
      type: Object,
      required: true,
    },
    attributeKey: {
      type: String,
      required: true,
    },
    itemValue: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getComponent() {
      switch (this.config.type) {
        case String:
          return StringField;
        case Number:
          return NumberField;
        case Boolean:
          return BooleanSwitch;
        case Date:
          return DatetimePicker;
        case types.Text:
          return TextArea;
        case types.PrivilegeArray:
          return PrivilegeArrayEditor;
        case types.Lookup:
          return LookupSelector;
        case types.LookupList:
          return LookupMultiSelector;
        case types.MultiSelect:
          return ObjectMultiSelect;
        case types.Map:
          return MapEditor;
        case types.StoryImage:
          return StoryImageChooser;
        case types.StoryVersionHistory:
          return StoryVersionHistory;
      }
    },
  },
  render(h) {
    return h(this.getComponent(), {
      on: this.$listeners,
      props: {
        config: this.config,
        itemValue: this.itemValue,
        attributeKey: this.attributeKey,
        attributeValue: this.itemValue[this.attributeKey],
        disabled: this.disabled,
      },
    });
  },
});
