<template>
  <div :class="$style.self" class="pb-6">
    <p class="caption mb-0">{{ config.label }}</p>
    <div :class="$style.container" @click="showModalEditor">
      <v-chip-group column>
        <v-chip v-for="item in value" :key="item.id" small>{{
          item.value
        }}</v-chip>
        <v-icon style="margin-left: auto;" dense>mdi-pencil</v-icon>
      </v-chip-group>
    </div>
  </div>
</template>

<script>
import LookupMultiSelectorModal from './LookupMultiSelectorModal';

export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
    attributeValue: {
      type: Array,
      default: () => [],
    },
    attributeKey: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [],
    };
  },
  computed: {
    value() {
      // Remove null from this field's value
      return (this.attributeValue || []).filter(lookup => !!lookup);
    },
  },
  created() {
    this.initializeCRUDProperty();
  },
  methods: {
    showModalEditor() {
      this.$modal
        .loadModal(LookupMultiSelectorModal, {
          lookupUrl: this.config.lookupUrl,
          selectedItems: this.attributeValue,
        })
        .then(this.mutate);
    },
    mutate(newValue) {
      this.$emit('mutate', { [this.attributeKey]: newValue });
    },
    initializeCRUDProperty() {
      this.mutate(this.attributeValue);
    },
  },
};
</script>

<style lang="scss" module>
.self {
  width: auto;
}
.container {
  border-bottom: 1px solid gray;
  min-height: 36px;
  cursor: pointer;
}
.addIcon {
  cursor: pointer;
  &:hover {
    color: $theme-color-secondary;
  }
}
</style>
