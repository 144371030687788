<template>
  <v-select
    :value="attributeValue"
    :label="config.label"
    :items="options"
    :loading="isLoading"
    item-text="value"
    item-value="id"
    @input="mutate"
  />
</template>

<script>
import api from '../../../../../utils/api-axios';
export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
    attributeValue: {
      type: Object,
      default: () => ({}),
    },
    attributeKey: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [],
      isLoading: false,
    };
  },
  created() {
    this.loadOptions().then(() => {
      this.initializeCRUDProperty();
    });
  },
  methods: {
    initializeCRUDProperty() {
      this.mutate((this.attributeValue || {}).id);
    },
    loadOptions() {
      this.isLoading = true;
      return api.get(this.config.lookupUrl).then(response => {
        this.$set(this, 'options', response.data);
        this.isLoading = false;
      });
    },
    mutate(value) {
      this.$emit('mutate', {
        [this.attributeKey]:
          this.options.filter(option => option.id === value)[0] || null,
      });
    },
  },
};
</script>
