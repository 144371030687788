<template>
  <v-card :class="$style.self">
    <v-container
      class="pa-2"
      :class="$style.container"
      @click="$emit('resolve', true)"
    >
      <img :src="image" alt="imgSrc" />
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" module>
.self {
  max-width: 80vw;
  max-height: 80vw;
  overflow: hidden;
}
.container {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  img {
    width: 100%;
  }
}
</style>
