<template>
  <div>
    <TopBar :title="title">
      <template v-slot:left>
        <v-btn rounded icon @click="goBack">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </template>
      <v-btn v-if="deleteHandler" text rounded @click="displayDeletePopup">
        Delete
      </v-btn>
      <v-btn color="primary" rounded :disabled="isUnchanged" @click="submit">
        Submit
      </v-btn>
    </TopBar>
    <v-container class="px-9">
      <v-card class="pa-2">
        <v-row>
          <v-col
            v-for="(fg, tableIndex) in formGroups"
            :key="tableIndex"
            :class="['fieldGroup', fg.tabNames ? 'px-1 py-1' : 'px-8 py-3']"
            :cols="fg.cols"
          >
            <template v-if="fg.tabNames">
              <v-tabs>
                <v-tab v-for="name in fg.tabNames" :key="name">
                  {{ name }}
                </v-tab>
                <v-tab-item
                  v-for="tabIndex in fg.tabNames.length"
                  :key="tabIndex"
                  :eager="true"
                >
                  <div class="px-7 py-4">
                    <ItemFormField
                      v-for="(dataConfig, key) in fg.fields[tabIndex - 1]"
                      :key="key"
                      :config="dataConfig"
                      :item-value="value"
                      :attribute-key="key"
                      :disabled="
                        (!allowChangingId && key === config.identifierKey) ||
                          dataConfig.disabled
                      "
                      @mutate="mutate"
                    />
                  </div>
                </v-tab-item>
              </v-tabs>
            </template>
            <template v-else>
              <ItemFormField
                v-for="(dataConfig, key) in fg.fields"
                :key="key"
                :config="dataConfig"
                :item-value="value"
                :attribute-key="key"
                :disabled="
                  (!allowChangingId && key === config.identifierKey) ||
                    dataConfig.disabled
                "
                @mutate="mutate"
              />
            </template>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import ItemFormField from './ItemFormField';
import TopBar from '../../../components/common/TopBar';
import { mapActions, mapMutations } from 'vuex';
import deepEquals from '../../../utils/deepEquals';
import clone from 'clone-deep';

export default {
  components: {
    ItemFormField,
    TopBar,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    submitHandler: {
      type: Function,
      required: true,
    },
    deleteHandler: {
      type: Function,
      default: null,
    },
    initialValue: {
      type: Object,
      default: () => ({}),
    },
    submitMessage: {
      type: String,
      default: 'Loading...',
    },
    submitSuccessMessage: {
      type: String,
      default: 'Loaded successfully.',
    },
    deleteMessage: {
      type: String,
      default: 'Deleting...',
    },
    deleteSuccessMessage: {
      type: String,
      default: 'Deleted successfully.',
    },
    allowChangingId: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: clone(this.initialValue),
      sessionStorageNamespace: 'PAGE STORAGE ' + this.$route.path,
    };
  },
  computed: {
    isUnchanged() {
      return deepEquals(this.initialValue, this.value);
    },
    formGroups() {
      return Array.isArray(this.config.form)
        ? this.config.form
        : [this.config.form];
    },
  },
  watch: {
    value: {
      handler() {
        this.saveValueToSession();
      },
      deep: true,
    },
  },
  created() {
    this.loadValueFromSession();
  },
  methods: {
    ...mapActions(['setLoaderSnackbar']),
    ...mapMutations(['displayPopup']),
    mutate(payload) {
      Object.keys(payload).forEach(key => {
        this.$set(this.value, key, payload[key]);
      });
    },
    submit() {
      const promise = this.submitHandler(
        this.transformSubmitRequest(clone(this.value)),
        {
          previous: this.initialValue,
        },
      );

      // eslint-disable-next-line no-console
      if (!promise) console.error('submitHandler should return a promise!');

      promise.then(() => {
        this.removeValueFromSession();
        this.goBack();
      });

      this.setLoaderSnackbar({
        promise,
        text: this.submitMessage,
        successText: this.submitSuccessMessage,
      });
    },
    transformSubmitRequest(data) {
      if (this.config.transformSubmitRequest) {
        data = this.config.transformSubmitRequest(data);
      }
      return data;
    },
    goBack() {
      this.reset();
      this.$router.push({ name: 'CRUDListItems' });
    },
    reset() {
      this.removeValueFromSession();
      this.$set(this, 'value', clone(this.initialValue));
    },
    displayDeletePopup() {
      const type = this.config.name.toLowerCase();
      const name = this.value[
        this.config.labelKey || this.config.identifierKey
      ];
      this.$modal
        .loadConfirmation({
          header: `You are about to delete a ${type}.`,
          text: `Are you sure you want to delete ${name}? This action is permanent.`,
        })
        .then(confirmed => (confirmed ? this.deleteItem() : null));
    },
    deleteItem() {
      const promise = this.deleteHandler();

      // eslint-disable-next-line no-console
      if (!promise) console.error('deleteHandler should return a promise!');

      promise.then(() => {
        this.removeValueFromSession();
        this.goBack();
      });

      this.setLoaderSnackbar({
        promise,
        text: this.deleteMessage,
        successText: this.deleteSuccessMessage,
      });
    },
    loadValueFromSession() {
      const json = sessionStorage.getItem(
        this.sessionStorageNamespace + ' value',
      );
      const val = JSON.parse(json);
      if (val) {
        this.$set(this, 'value', val);
      }
    },
    saveValueToSession() {
      sessionStorage.setItem(
        this.sessionStorageNamespace + ' value',
        JSON.stringify(this.value),
      );
    },
    removeValueFromSession() {
      sessionStorage.removeItem(this.sessionStorageNamespace + ' value');
    },
  },
};
</script>

<style lang="scss" scoped>
.fieldGroup:not(:first-of-type) {
  border-left: $theme-border;
}
</style>
