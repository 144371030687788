<template>
  <v-text-field
    :value="attributeValue"
    :label="config.label"
    :disabled="disabled"
    @input="mutate"
  />
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
    attributeValue: {
      type: String,
      default: '',
    },
    attributeKey: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.initializeCRUDProperty();
  },
  methods: {
    mutate(newValue) {
      this.$emit('mutate', { [this.attributeKey]: newValue });
    },
    initializeCRUDProperty() {
      this.mutate(this.attributeValue);
    },
  },
};
</script>
