<template>
  <v-container class="pa-0 mb-5">
    <v-row>
      <v-col>
        <v-img
          :src="displayImageSrc"
          :class="$style.image"
          alt="The cover image for this story."
          @click="showStoryImageModal"
        />
        <v-card class="mx-3 px-2" elevation="1" :class="$style.card">
          <v-file-input :label="config.label" @change="mutate" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StoryImageModal from './StoryImageModal';

export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
    itemValue: {
      type: Object,
      required: true,
    },
    attributeValue: {
      type: [File, Object],
      default: null,
    },
    attributeKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loadedImageSrc: null,
    };
  },
  computed: {
    currentImage() {
      return this.itemValue.image || {};
    },
    displayImageSrc() {
      return this.loadedImageSrc
        ? this.loadedImageSrc
        : this.currentImage.original;
    },
  },
  watch: {
    attributeValue() {
      if (this.attributeValue) this.loadImageFromAttributeValue();
    },
  },
  methods: {
    mutate(newValue) {
      this.$emit('mutate', { [this.attributeKey]: newValue });
    },
    loadImageFromAttributeValue() {
      const fr = new FileReader();
      fr.onload = () => {
        this.loadedImageSrc = fr.result;
      };
      fr.readAsDataURL(this.attributeValue);
    },
    showStoryImageModal() {
      if (this.displayImageSrc)
        this.$modal.loadModal(StoryImageModal, { image: this.displayImageSrc });
    },
  },
};
</script>

<style lang="scss" module>
.image {
  margin-bottom: -80px;
  max-height: 256px;
  height: 256px;
  background-color: gray;
  border-radius: $theme-border-radius;
  cursor: pointer;
}
</style>
