import { render, staticRenderFns } from "./StoryImageModal.vue?vue&type=template&id=08240723&"
import script from "./StoryImageModal.vue?vue&type=script&lang=js&"
export * from "./StoryImageModal.vue?vue&type=script&lang=js&"
import style0 from "./StoryImageModal.vue?vue&type=style&index=0&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VContainer})
