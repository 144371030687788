<template>
  <v-card v-if="hasLoaded" :class="$style.self">
    <v-container class="pt-0">
      <v-text-field
        v-model="filter"
        placeholder="Filter..."
        class="mt-0"
        autocomplete="off"
      ></v-text-field>
      <div :class="$style.selectionSection">
        <v-row v-for="item in filteredOptions" :key="item.id" dense>
          <v-col cols="auto">
            <v-simple-checkbox
              :value="selectedIds.includes(item.id)"
              :ripple="false"
              @input="selected => updateSelected(item, selected)"
            />
          </v-col>
          <v-col cols="auto">
            <span class="body-1">{{ item.value }}</span>
          </v-col>
        </v-row>
      </div>
      <div v-if="filteredOptions.length === 0">
        <v-row dense>
          <v-col>
            <v-btn text dense class="ma-0" @click="createLookupFromFilter"
              >Create "{{ filter }}"</v-btn
            >
          </v-col>
        </v-row>
      </div>
      <v-btn width="100%" class="mt-4" depressed @click="resolve">
        Done
      </v-btn>
    </v-container>
  </v-card>
</template>

<script>
import api from '../../../../../../utils/api-axios';
import clone from 'clone-deep';
export default {
  props: {
    selectedItems: {
      type: Array,
      default: () => [],
    },
    lookupUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      options: [],
      selected: clone(this.selectedItems),
      filter: '',
      hasLoaded: false,
    };
  },
  computed: {
    selectedIds() {
      return this.selected.map(item => item.id);
    },
    filteredOptions() {
      const regexFilter = RegExp(`.*${this.filter}.*`, 'i');
      return this.options.filter(option => {
        return regexFilter.test(option.value);
      });
    },
  },
  created() {
    this.fetchOptions().then(() => (this.hasLoaded = true));
  },
  methods: {
    beforeCloseModal() {
      this.resolve();
    },
    fetchOptions() {
      return api
        .get(this.lookupUrl)
        .then(res => res.data)
        .then(options => options.sort(this.sortSelectedOnTop))
        .then(options => this.$set(this, 'options', options));
    },
    resolve() {
      this.$emit('resolve', this.selected);
    },
    updateSelected(item, add) {
      if (add) {
        this.selected.push(item);
      } else {
        this.selected = this.selected.filter(s => s.id !== item.id);
      }
    },
    createLookupFromFilter() {
      api
        .post(this.lookupUrl, { value: this.filter })
        .then(() => this.fetchOptions());
    },
    sortSelectedOnTop(a, b) {
      if (this.selectedIds.includes(a.id) && !this.selectedIds.includes(b.id)) {
        return -1;
      } else if (
        !this.selectedIds.includes(a.id) &&
        this.selectedIds.includes(b.id)
      ) {
        return 1;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mdi-checkbox-marked {
  color: $theme-color-secondary;
}
</style>

<style lang="scss" module>
.self {
  width: 300px;
}

.selectionSection {
  max-height: 300px;
  overflow-y: scroll;
  padding-right: 20px;

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 100px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 100px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
</style>
