<template>
  <v-select
    :value="attributeValue"
    :loading="isLoadingOptions"
    :label="config.label"
    :items="options"
    :item-text="config.itemText"
    :item-value="config.itemValue"
    multiple
    chips
    @input="mutate"
  />
</template>

<script>
import isFunction from 'lodash.isfunction';
export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
    attributeValue: {
      type: Array,
      default: () => [],
    },
    attributeKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      resolvedOptions: [],
      isLoadingOptions: false,
    };
  },
  computed: {
    options() {
      return isFunction(this.config.options)
        ? this.resolvedOptions
        : this.config.options;
    },
  },
  created() {
    this.resolveOptions();
  },
  methods: {
    async resolveOptions() {
      if (isFunction(this.config.options)) {
        this.isLoadingOptions = true;
        const options = await this.config.options();
        this.$set(this, 'resolvedOptions', options);
        this.isLoadingOptions = false;
      }
    },
    mutate(selectedObjects) {
      const newValue = this.options.filter(option =>
        selectedObjects.includes(option[this.config.itemText]),
      );
      this.$emit('mutate', { [this.attributeKey]: newValue });
    },
  },
};
</script>

<style lang="scss" scoped></style>
